import { Link } from "gatsby";
import React from "react";

// This file is used at : [ buildMVP.js | frontend.js | backend.js ]

const ServicesHomeComponent = (props) => {
  const { servicesHomeTitle, servicesDescription, isProcess } = props;
  return (
    <div
      className="services-home-section"
      style={{ backgroundImage: `url(${props.servicesBackground})` }}
    >
      {/* <HeaderComponent active={2} /> */}
      <div className="full-container">
        <div className="services-home-section-text">
          <h1 className="services-home-section-title">{servicesHomeTitle}</h1>
          <p
            className="services-home-section-description"
            dangerouslySetInnerHTML={{
              __html: servicesDescription,
            }}
          ></p>
          {isProcess && (
            <Link to="/contact" className="services-home-section-link">
              Let's Talk
            </Link>
          )}
          {/* {isMVP && (
                    <div className="services-home-section-wrapper">
                        <p className="services-home-section-description">“We’re all about helping you build meaningful products. We focus on three things: Growth, Product, and Process.” We believe transparency is the key to building profitable businesses. 
Our SaaS Platform allows you to build and deploy complete, fully functional, and highly complex web applications in just one click. </p>
                        <p className="services-home-section-description">With our revolutionary SaaS Model, our technology and cloud-based platform can be easily deployed across any industry - from startups to Fortune 500 companies. Multi-tenancy application design, implementation, deployment, and maintenance are all covered by Software-as-a-Service (SaaS) development services. </p>
                        <p className="services-home-section-description">Easily and quickly acquire your digital presence with a SaaS deployment model that delivers increased flexibility, scalability, and improved security.</p>
                    </div>
                )} */}
        </div>
      </div>
    </div>
  );
};
export default ServicesHomeComponent;
