import { Link } from "gatsby";
import React from "react";

// This file is used at : [ buildMVP.js | frontend.js ]

const LetsTalkComponent = () => {
  return (
    <div className="full-container">
      <div className="lets-talk-section">
        <Link to="/contact" className="lets-talk-section-link">
          Let’s Talk
        </Link>
      </div>
    </div>
  );
};
export default LetsTalkComponent;
