import React from "react";
import TitleIcone from "../../../static/assets/Features/TitleIcon.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// This file is used at : [ buildMVP.js | frontend.js | backend.js ]

const MVPProcessComponent = (props) => {
  const {
    MVPTitle,
    MVPDescription,
    MVPBuildImage,
    isLeft,
    isEnd,
    isTesting,
    isEnhance,
    isFrontEnd,
    sassMvpText,
    sassMvpDescription,
    sassMvpImage,
    isSassMVP,
  } = props;
  return (
    <div className="full-container">
      <div className={`mvp-section ${isEnd ? "mvp-section-end" : ""}`}>
        <div className="row">
          <div className={` col-md-6 ${isLeft ? "order-md-2" : ""}`}>
            <div className="home-section-textIcone">
              {!isEnhance && <TitleIcone className="home-section-titleIcone" />}
              <p className="mvp-section-title ">{MVPTitle}</p>
            </div>
            <p
              className={`mvp-section-description ${
                isEnhance ? "mvp-section-description-enhance" : ""
              }`}
              dangerouslySetInnerHTML={{ __html: MVPDescription }}
            ></p>
            {isSassMVP && (
              <div>
                <p
                  className={`mvp-section-description ${
                    isEnhance ? "mvp-section-description-enhance" : ""
                  }`}
                >
                  {sassMvpText}
                </p>
                <p
                  className={`mvp-section-description ${
                    isEnhance ? "mvp-section-description-enhance" : ""
                  }`}
                >
                  {sassMvpDescription}
                </p>
              </div>
            )}
            {isFrontEnd && (
              <Link to="/contact" className="mvp-section-link">
                Let's Talk
              </Link>
            )}
          </div>
          <div
            className={`col-md-6 mvp-section-wrapper ${
              isLeft ? "order-md-1" : ""
            }`}
          >
            {isSassMVP && (
              <img
                src={sassMvpImage}
                alt="sassMvpImage"
                className="mvp-section-image"
              />
            )}
            <GatsbyImage
              alt="MVPBuildImage"
              image={MVPBuildImage}
              className={` ${
                isTesting ? "mvp-section-image-testin" : "mvp-section-image"
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MVPProcessComponent;
