import React from 'react';
import Layout from '../component/layout';
import MVPProcessComponent from '../component/Services/mvpProcessComponent';
import { graphql } from 'gatsby';
import FooterComponent from '../component/footerComponent';
import ServicesHomeComponent from '../component/Services/servicesHomeComponent';
import RequiremantImage from '../images/frontBackRequirementsImage.png';
import EstimationImage from '../images/backFrontEstimation.png';
import DevelopementFrontBack from '../images/backFrontDevelopement.png';
import SupportFrontBack from '../images/supportFrontBack.png';
import FrontBackImage from '../images/frontBackImage.png';
import LetsTalkComponent from '../component/Services/letsTalkComonent';
import DeliveryFrontBack from '../images/deliveryFrontBack.png';

const FrontEndDeveloper = props => {
  const {
    data: {
      wpPost: {
        seo,
        serviciesHeader: { servicesHomeTitle, servicesHomeDescription },
        // buildMvpSection: {
        // buildMvpSectionDescription,
        // buildMvpSectionTitle,
        // designeAndPropertyDescription,
        // designeAndPropertyTitle,
        // implemantationDescription,
        // implemantationTitle,
        // productIdeaDescription,
        // productIdeaTitle,
        // supportDescription,
        // supportTitle,
        // buildMvpSectionImage: {
        //   localFile: {
        //     childImageSharp: { gatsbyImageData: buildMvpSectionImage },
        //   },
        // },
        // designeAndPropertyImage: {
        //   localFile: {
        //     childImageSharp: { gatsbyImageData: designeAndPropertyImage },
        //   },
        // },
        // implemantationImage: {
        //   localFile: {
        //     childImageSharp: { gatsbyImageData: implemantationImage },
        //   },
        // },
        // productIdeaImage: {
        //   localFile: {
        //     childImageSharp: { gatsbyImageData: productIdeaImage },
        //   },
        // },
        // supportImage: {
        //   localFile: {
        //     childImageSharp: { gatsbyImageData: supportImage },
        //   },
        // },
        // },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout seo={seo} title={title}>
      <ServicesHomeComponent
        servicesHomeTitle={servicesHomeTitle}
        servicesDescription={servicesHomeDescription}
        isProcess={true}
      />
      <div className='enhance-section-background'>
        <MVPProcessComponent
          MVPTitle='Requirements'
          MVPDescription="We will help you define the core functionalities of your application or website. We'll define the needs for your product, which may include a business need, technical need, or both."
          sassMvpText='This essential step will provide you with an understanding of what features are required so that you can work with the business consultants and software architects to build a plan that meets your needs.'
          isEnhance={true}
          isLeft={true}
          isSassMVP={true}
          sassMvpImage={RequiremantImage}
        />
        <MVPProcessComponent
          MVPTitle='Estimation'
          MVPDescription='Once the requirements are collected, it is time to create a prototype. Our prototype will manifest the basic idea of the future application helping us have a clearer view of what additional features will be included for an innovative product.'
          sassMvpText='We will make sure the end product is on the right track by continuously basing our taken actions on a product that represents market and customers needs.'
          isTesting={true}
          isEnhance={true}
          isSassMVP={true}
          sassMvpImage={EstimationImage}
        />
        <MVPProcessComponent
          MVPTitle='Development'
          MVPDescription='Our experience enables us to deliver software products that meet your needs and expectations. We are excited to work with you to develop the next great software product.'
          sassMvpText='To ensure that the product is created in the most effective manner, our frontend developers identify the finest front-end frameworks, tools, and development techniques, while our back-end developers will focus on delivering server-side software code, setting up databases and resolving code bugs.'
          isEnhance={true}
          isLeft={true}
          isSassMVP={true}
          sassMvpImage={DevelopementFrontBack}
        />
        <MVPProcessComponent
          MVPTitle='Delivery'
          MVPDescription='Our frontend developers raise the bar for excellence-speed, operational efficiency, reliability, and security all in a well-round product while ensuring the defects will not get in the way of successful delivery. '
          sassMvpText='Our backend developers will offer greater product quality, making sure it has exceeded the client’s expectations has a scalable architecture and is now an ambitious and innovative addition to the technology world.'
          isTesting={true}
          isEnhance={true}
          isSassMVP={true}
          sassMvpImage={DeliveryFrontBack}
        />
        <MVPProcessComponent
          MVPTitle='Support'
          MVPDescription='Motomtech’s team will provide post-operation support so that the application never experiences issues, runs slowly, or crashes. They will optimize the code, and databases, be available to fix bugs that may occur or be able to implement new features that can later be required and necessary for the success of the application. We aim for consistent, unproblematic performance.'
          isEnhance={true}
          isLeft={true}
          isSassMVP={true}
          sassMvpImage={SupportFrontBack}
        />
        <MVPProcessComponent
          MVPTitle='Our Front-end / Back-end tech stackry'
          MVPDescription="Our tech stack incorporates the latest technologies, tools, and frameworks. 
              Almost any software infrastructure's success is largely dependent on front-end technologies. 
              Our front-end developers are highly experienced in HTML, CSS, Responsive Web design, React JS, Node JS, Javascript, PSD to BootStrap, and CMS services."
          sassMvpText='The backend development remains invisible as it provides the ‘backbone’ of the application. 
              We pride ourselves in being experts on programming languages, such as Ruby and Python; libraries and frameworks such as Ruby on Rails and Django; JavaScript and Node.js.'
          isEnd={true}
          isEnhance={true}
          // isLeft={true}
          // isFrontEnd={true}
          // isTesting={true}
          isSassMVP={true}
          sassMvpImage={FrontBackImage}
        />
        <LetsTalkComponent />
      </div>
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPost(slug: { eq: "frontend-development-page" }) {
      seo {
        metaDesc
        title
      }
      serviciesHeader {
        servicesHomeTitle
        servicesHomeDescription
      }
      buildMvpSection {
        buildMvpSectionDescription
        buildMvpSectionTitle
        designeAndPropertyDescription
        designeAndPropertyTitle
        implemantationDescription
        implemantationTitle
        productIdeaDescription
        productIdeaTitle
        supportDescription
        supportTitle
        buildMvpSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        designeAndPropertyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        implemantationImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        productIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        supportImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default FrontEndDeveloper;
